/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        // Fastclick
        FastClick.attach(document.body);

        // Remove empty p tags
        $('p:empty').remove();

        // External links
        $('a').filter(function(){
          return this.hostname && this.hostname !== location.hostname;
        }).addClass('external').attr('target', '_blank');


        //*Mobile Menu*//
        $( ".c-header__toggle" ).click(function(e) {
          e.preventDefault();

          $('.c-mobile__menu').animate({height: 'toggle'});
        });

        // for the mobile animation

        $(".c-header__toggle").click(function(){
          $(this).toggleClass("is-active");
        });



        //   Intro Image
        var colintro = $('.section--intro').data('desktop'),
        colmintro = $('.section--intro').data('mobile');
        if(viewportWidth < 767 ){
          $('.section--intro').css('background-image', 'url('+colmintro+')');
        }else{
          $('.section--intro').css('background-image', 'url('+colintro+')');
        }

        //   Amenitie Image
        var amenities = $('.a-back').data('desktop'),
        amenities_mobile = $('.a-back').data('mobile');
        if(viewportWidth < 767 ){
          $('.a-back').css('background-image', 'url('+amenities_mobile+')');
        }else{
          $('.a-back').css('background-image', 'url('+amenities+')');
        }


        //   Activities Image
        var activities = $('.activites-back').data('desktop'),
        activities_mobile = $('.activites-back').data('mobile');
        if(viewportWidth < 767 ){
          $('.activites-back').css('background-image', 'url('+activities_mobile+')');
        }else{
          $('.activites-back').css('background-image', 'url('+activities+')');
        }




        //   Careers Image
        var career = $('.career-back').data('desktop'),
        career_mobile = $('.career-back').data('mobile');
        if(viewportWidth < 400 ){
          $('.career-back').css('background-image', 'url('+career_mobile+')');
        }else{
          $('.career-back').css('background-image', 'url('+career+')');
        }

        //   Secondary Hero
        var hero = $('.s_hero-back').data('desktop'),
        heromobile = $('.s_hero-back').data('mobile');
        if(viewportWidth < 400 ){
          $('.s_hero-back').css('background-image', 'url('+heromobile+')');
        }else{
          $('.s_hero-back').css('background-image', 'url('+hero+')');
        }

        // Mobile Drop Menu Primary Nav
        $(function () {
          var children = $('.m-nav > li > a').filter(function () { return $(this).nextAll().length > 0 ;});
          $('<span class="drop-menu"><i class="fas fa-chevron-down"></i></span>').insertAfter(children);
          $('.m-nav .drop-menu').click(function (e) {
            $(this).next().slideToggle(300);
            return false;
          });
        });



                        // Page loader
                        // IE10+
                        document.getElementsByTagName( "html" )[0].classList.remove( "loading" );

                        // All browsers
                        document.getElementsByTagName( "html" )[0].className.replace( /loading/, "" );

                        // Or with jQuery
                        $( "html" ).removeClass( "loading" );



      },


      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
          // tasks to do if it is a Mobile Device
          $(function () {
            var children = $('.p-nav > li > a').filter(function () { return $(this).nextAll().length > 0 ;});
            $('<span class="drop-menu"><i class="fas fa-chevron-down"></i></span>').insertAfter(children);
            $('.p-nav .drop-menu').click(function (e) {
              $(this).next().slideToggle(300);
              return false;
            });
          });

        }

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
